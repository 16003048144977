<template>
  <div id="store-reviews">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
      color="#198b4a"
    ></Loading>
    <ProfileDropdown v-if="isLoggedIn" id="profile-dropdown"></ProfileDropdown>
    <WhiteHeader v-if="store" :bannerImage="store.bannerLink" :showNavigation="false"></WhiteHeader>
    <div
      id="mask"
      @click="onClickOutside"
      :class="{
        mask: isEntranceOpen || isLanguageModalOpen,
      }"
    ></div>
    <div id="store-info">
      <StoreHeader id="store" :store="store" :shouldShowMenu="false"></StoreHeader>
    </div>
    <div ref="body" id="body">
      <InfiScroller :has-more="hasMore" :on-load-more="onLoadMore" :gutter="600">
        <Review v-for="(review, index) in reviews" :key="index" :review="review"></Review>
        <div id="no-reviews" v-if="emptyContent">
          <h4>
            {{ labels.review.noReviews[language] }}
            <span
              style="font-style: normal !important; margin-left: 5px"
            >🥺</span>
          </h4>
        </div>
      </InfiScroller>
      <div id="loading-more" v-if="hasMore">
        <h4>{{ labels.review.loadingMore[language] }} ⌛</h4>
      </div>
    </div>
  </div>
</template>

<script>
import EnterPrompt from "../EnterPrompt";
import ProfileDropdown from "../ProfileDropdown";
import InfiScroller from "vue-infi-scroller";
import WhiteHeader from "../WhiteHeader";
import StoreHeader from "../StoreHeader";
import Review from "../Review";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import StoreApi from "../../api/stores";

export default {
  name: "StoreReviews",
  components: {
    EnterPrompt,
    ProfileDropdown,
    InfiScroller,
    WhiteHeader,
    StoreHeader,
    Loading,
    Review,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    let storeId = this.$router.currentRoute.params.storeId;
    let store = await StoreApi.getStoreById(storeId);

    if (!store.ratingEnabled) {
      this.$router.push({ name: "search" });
    }
    this.store = store;

    this.$store.dispatch("setStoreId", store.id);
    this.$store.dispatch("setStoreIsHotel", store.hotel)

    let result = await StoreApi.getStoreReviews(store.id, 0);
    this.reviews = result.content;
    this.hasMore = !result.last;

    this.refBody = this.$refs.body;

    this.isLoading = false;
    this.setTitle();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    emptyContent() {
      return this.reviews.length == 0;
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    title() {
      if (this.store) {
        return `${this.labels.titles.storeReviews[this.language]} - ${this.storeName
          } | Fluter`;
      } else {
        return `${this.labels.titles.storeReviews[this.language]} | Fluter`;
      }
    },
  },
  methods: {
    onLoadMore: async function () {
      let result = await StoreApi.getStoreReviews(this.store.id, this.page);
      this.page++;
      this.hasMore = !result.last;
      this.reviews = [...this.reviews, ...result.content];
    },

    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
  data() {
    return {
      isLoading: true,
      store: undefined,
      reviews: [],
      bodyRef: undefined,
      page: 1,
      hasMore: true,
    };
  },
};
</script>

<style scoped>
#body {
  min-height: 60vh;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10% 0 10% 0;
}

#store-info {
  position: absolute;
  top: 25%;
  left: 5%;
  width: 45%;
  min-width: 400px;
  background: white;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#store {
  cursor: unset;
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 800px) {
  #profile-dropdown {
    top: 67px;
  }
}

#loading-more {
  margin: 10% 0 0% 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loading-more > h4 {
  margin: 0;
  color: grey;
  font-style: italic;
  font-size: 14px;
  padding-bottom: 1%;
  text-align: center;
}

.hide {
  visibility: hidden;
  display: none;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#no-reviews {
  margin: 2% 0 2% 0;
  width: 90%;
  display: flex;
}
@media screen and (max-width: 800px) {
  #no-reviews {
    width: 80%;
  }
}

#no-reviews > h4 {
  margin: 0;
  margin: auto;
  color: grey;
  font-style: italic;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 800px) {
  #no-reviews > h4 {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 800px) {
  #store-info {
    min-width: 100px;
    width: 90%;
  }
}

@media (max-height: 300px) {
  #store-info {
    top: 100px;
  }
}
</style>