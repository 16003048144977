

<template>
  <div id="review">
    <div id="date-n-name">
      <h3 id="date">{{ formattedDate }}</h3>
      <h3 id="name">{{ review.name }}</h3>
    </div>

    <div
      id="comment"
    >{{ review.comment != "" ? review.comment : labels.review.noCommentInReview[language] }}</div>
    <div id="stars">
      <font-awesome-icon
        v-for="(n, index) in review.rating"
        :key="index"
        class="yellow space review-star"
        icon="star"
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "Review",
  components: {},
  computed: {
    formattedDate() {
      let dateObj = new Date(this.review.date);
      let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      let day = ("0" + dateObj.getDate()).slice(-2);
      let year = dateObj.getFullYear();
      return `${day}/${month}/${year}`
    },

    language() {
      return this.$store.getters.getLanguage
    }
  },
  data() {
    return {};
  },
  props: ["review"],
  methods: {},
};
</script>

<style scoped>
#review {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5%;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
}

#date-n-name {
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: space-between;
}

#date,
#name {
  /* width: 100%; */
  margin: 0;
  /* padding: 1%; */
  font-size: 12px;
  color: #198b4a;
  text-align: end;
  font-weight: 600;
  max-width: 55%;
  /* background: blue; */
}

#comment {
  margin: 0;
  color: grey;
  font-style: italic;
  font-size: 14px;
  padding-bottom: 1%;
}

#stars {
  margin-bottom: 1.5%;
}

.yellow {
  color: #ffc200;
}

@media screen and (max-width: 800px) {
  #random-review {
    margin-top: 4.5%;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  }

  #date {
    padding: 1%;
    margin-bottom: 6%;
    font-size: 10px;
  }

  #comment {
    font-size: 12px;
    padding-bottom: 3%;
  }

  #stars {
    margin-bottom: 4.5%;
  }

  .review-star {
    width: 14px;
    height: 14px;
  }
}
</style>