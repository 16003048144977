<template>
  <div id="store" v-if="store">
    <div v-if="!hideImage" id="image-container">
      <img @error="showNoImage" id="store-image" :src="`${store.logoLink}`" alt />
    </div>
    <div id="store-info-container">
      <div id="title-container">
        <h3>{{ store.names && store.names.translation[language] ? store.names.translation[language].name : store.name }}</h3>
      </div>
      <div id="store-info">
        <h4
          id="street"
        >{{ store.names && store.names.translation[language] ? store.names.translation[language].address : store.address }}</h4>
      </div>
      <div id="categories" class="add-space">{{ categoriesToString[language] }}</div>
    </div>
    <div id="rating-container">
      <h4 id="rating">{{ store.totalRating }}</h4>
      <div id="stars">
        <font-awesome-icon
          v-for="(i, index) in roundedRating"
          :key="index"
          id="star-icon"
          icon="star"
          :style="{ color: `#ffc200` }"
        ></font-awesome-icon>
      </div>
      <h4 id="from-reviews-number">
        {{ labels.store.fromXReviews.from[language] }}
        <span
          id="reviews-number"
        >{{ store.amountOfRatings }}</span>
        {{ labels.store.fromXReviews.reviews[language] }}
      </h4>
    </div>
  </div>
</template>


<script>
export default {
  name: "StoreHeader",
  components: {},
  computed: {
    categoriesToString() {
      let engCategories = [];
      let grCategories = [];

      if (this.store.storeCategories) {
        this.store.storeCategories.forEach(category => {
          if (category.names) {
            if (category.names.translation.ENG) {
              engCategories.push(category.names.translation.ENG.name);
            } else {
              engCategories.push(category.name);
            }
            if (category.names.translation.GRE) {
              grCategories.push(category.names.translation.GRE.name);
            } else {
              grCategories.push(category.name);
            }
          } else {
            engCategories.push(category.name);
            grCategories.push(category.name);
          }
        });
      }

      return {
        ENG: engCategories.join(", "),
        GRE: grCategories.join(", "),
      }
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    roundedRating() {
      if (this.store.totalRating) {
        return Math.round(this.store.totalRating);
      } else {
        return Math.round(0);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      hideImage: false,
    };
  },
  props: ["store"],
  methods: {
    showNoImage: function () {
      this.hideImage = true
    }
  },
};
</script>

<style scoped>
#store {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 3% 0 3% 0;
  cursor: pointer;
  z-index: 1;
}

#image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 100%;
}

#store-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
  border: 0.5px solid #198b4a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.36);
}

#store-info-container {
  /* width: 33%; */
  height: 100%;
  display: flex;
  /* background: chartreuse; */
  flex-direction: column;
  /* padding-left: 10%; */
}

#title-container > h3 {
  color: #198b4a;
  font-size: 25px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 7%;
}

#street {
  color: gray;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 7%;
}

#store-info > h3 {
  margin: 0;
  font-size: 15px;
  color: gray;
  display: flex;
  flex-direction: row;
  margin-bottom: 6%;
}

#rating-container {
  /* background: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
  height: 100%;
}

#rating {
  color: #198b4a;
  font-weight: 900;
  font-size: 26px;
}

#star-icon {
  width: 20px;
  height: 20px;
}

#from-reviews-number {
  margin: 0;
  margin-top: 10px;
  color: gray;
  font-size: 13px;
}

#reviews-number {
  font-size: 15px;
  font-weight: 700;
  color: #198b4a !important;
}

.categories-label {
  font-size: 15px;
  font-weight: bold;
  margin-right: 5px;
}

#categories {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
  color: gray;
  font-size: 15px;
  margin-bottom: 7%;
}

.increaseWidth {
  width: 80% !important;
  padding-left: 5%;
}

.add-space {
  margin-right: 5px;
}

@media screen and (max-width: 800px) {
  #store {
    margin: 0% 0 2% 0;
  }

  #image-container {
    margin-top: 4%;
  }

  #store-image {
    width: 60px;
    height: 60px;
  }

  #title-container > h3 {
    margin-top: 7%;
    font-size: 20px;
    margin-bottom: 3%;
  }

  #street {
    color: gray;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 3%;
  }

  #store-info > h3 {
    font-size: 12px;
    margin-bottom: 3%;
  }

  #rating-container {
    width: 40%;
  }

  #rating {
    color: #198b4a;
    font-weight: 900;
    font-size: 19px;
  }

  #star-icon {
    width: 16px;
    height: 16px;
  }

  #from-reviews-number {
    margin: 0;
    margin-top: 10px;
    color: gray;
    font-size: 11px;
  }

  #reviews-number {
    font-size: 12px;
    color: #198b4a !important;
  }

  .categories-label {
    font-size: 12px;
  }

  #categories {
    font-size: 12px;
    margin-bottom: 3%;
  }
}

@media (max-width: 350px) {
  #store-image {
    width: 54px;
    height: 54px;
  }

  #title-container > h3 {
    margin-top: 7%;
    font-size: 18px;
  }

  #street {
    color: gray;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 7%;
  }

  #store-info > h3 {
    font-size: 11px;
  }

  #rating {
    color: #198b4a;
    font-weight: 900;
    font-size: 17px;
  }

  #star-icon {
    width: 14px;
    height: 14px;
  }

  #from-reviews-number {
    margin: 0;
    margin-top: 10px;
    color: gray;
    font-size: 10px;
  }

  #reviews-number {
    font-size: 11px;
    color: #198b4a !important;
  }

  .categories-label {
    font-size: 11px;
  }

  #categories {
    font-size: 11px;
  }
}

@media (max-width: 300px) {
  #store-image {
    width: 50px;
    height: 50px;
  }

  #title-container > h3 {
    margin-top: 7%;
    font-size: 16px;
  }

  #street {
    color: gray;
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 7%;
  }

  #store-info > h3 {
    font-size: 10px;
  }

  #rating {
    /* padding-top: 10px; */
    color: #198b4a;
    font-weight: 900;
    font-size: 16px;
  }

  #star-icon {
    width: 13px;
    height: 13px;
    margin-bottom: 7px;
  }

  #from-reviews-number {
    margin: 0;
    margin-top: 0px;
    color: gray;
    font-size: 9px;
  }

  #reviews-number {
    font-size: 10px;
    color: #198b4a !important;
  }

  .categories-label {
    font-size: 10px;
  }

  #categories {
    font-size: 10px;
  }
}
</style>
